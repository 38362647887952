import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import {
  TStreamersListParams,
  IStreamerListResponse,
  TStreamerStats,
  TStreamerStatsPayload,
  TStreamTransactionsByGameProviderPayload,
  TStreamTransactionsPayload,
  TStreamTransactionsResponse,
  ICopyStakeReportResponse,
  ICopyStakeReportParams,
  ICopyStakeReportPdfParams,
  IStreamingScheduleListResponse,
  IStreamingScheduleListParams,
  TScheduleStreamResponse,
  ICopyStakeGamesResponse,
  ICopyStakeGamesParams,
  ICopyStakeGamesRemoveAllParams,
  ICopyStakeUsersBlacklistParam,
  ICopyStakeUsersBlacklistResponse,
  ICopyStakeStatsStreamerParams,
  ICopyStakeStatsStreamersResponse,
  DateRangeParams,
  ICopyStakeGameItemResponse,
  TCopystakeIntegration,
  TStreamConfigurationResponse
} from '@/api/schema';
import { AxiosResponse, AxiosPromise } from 'axios';

const BASE = (): string => buildUrl('/api/v1/{operatorId}');

const API_STREAMER = {
  LIST() {
    return `${BASE()}/streamer`;
  },
  DELETE(id: string): string {
    return `${BASE()}/streamer/${id}`;
  },
  CREATE(): string {
    return `${BASE()}/streamer`;
  },
  UPDATE(id: string | number): string {
    return `${BASE()}/streamer/${id}`;
  }
};

const API_STATS = (): string => `${BASE()}/copy-bet-stat`;
const API_BLACKLIST = (): string => `${BASE()}/blacklist`;
const API_INTEGRATION = (): string => `${BASE()}/streamer/integration`;

const API_GAME = (): string => `${BASE()}/streamer/game`;
const API_GAME_PERMISSION = (): string => `${API_GAME()}/permission`;

const API_SCHEDULE = (): string => `${BASE()}/streamer/schedule`;

const API_CONFIG = (): string => `${BASE()}/streamer/settings`;

export const getStreamConfiguration =
  (): Promise<TStreamConfigurationResponse> =>
    http
      .get(API_CONFIG())
      .then(({ data }: AxiosResponse<TStreamConfigurationResponse>) => data);

export const updateStreamConfiguration = (
  payload: Partial<TStreamConfigurationResponse>
): Promise<void> =>
  http.put(API_CONFIG(), payload);

export const deleteStreamer = (streamerId: string): Promise<void> =>
  http.delete(API_STREAMER.DELETE(streamerId));

export const getStreamersList = (
  params: TStreamersListParams
): Promise<IStreamerListResponse> =>
  http
    .get(API_STREAMER.LIST(), { params, cacheTime: 0 })
    .then(({ data }: AxiosResponse<IStreamerListResponse>) => data);

export const createStreamer = (payload: FormData): Promise<any> =>
  http.post(API_STREAMER.CREATE(), payload);

export const updateStreamer = (
  id: number | string,
  payload: FormData
): Promise<any> => http.put(API_STREAMER.UPDATE(id), payload);

export const getStreamStats = (
  params: TStreamerStatsPayload
): Promise<TStreamerStats> => {
  return http
    .get(API_STATS(), {
      params,
      cacheTime: 30 * 1000
    })
    .then(({ data }: AxiosResponse<TStreamerStats>) => data);
};

export const getStreamTransactions = (
  params: TStreamTransactionsPayload
): Promise<TStreamTransactionsResponse> => {
  return http
    .get('/api/v2/tx/copy-stake', {
      params,
      cacheTime: 30 * 1000
    })
    .then(({ data }: AxiosResponse<TStreamTransactionsResponse>) => data);
};

export const getStreamTransactionsByGameProvider = async (
  params: TStreamTransactionsByGameProviderPayload
): Promise<TStreamTransactionsResponse> => {
  const { data } = await http.get('/api/v2/tx/copy-stake/game-provider', {
    params,
    cacheTime: 30 * 1000
  });
  return data as TStreamTransactionsResponse;
};

export const downloadStreamCsvReport = (
  params: TStreamTransactionsPayload
): Promise<string> => {
  return http
    .get('/api/v2/tx/copy-stake/csv', { params })
    .then(({ data }: AxiosResponse<string>) => data);
};

export const downloadStreamCsvReportByGameProvider = async (
  params: TStreamTransactionsByGameProviderPayload
): Promise<string> => {
  const { data } = await http.get('/api/v2/tx/copy-stake/game-provider/csv', {
    params
  });
  return data as string;
};

export async function getCopyStakeReport(
  params: ICopyStakeReportParams
): Promise<ICopyStakeReportResponse> {
  return http
    .get(`${API_STATS()}/report`, {
      params
    })
    .then(({ data }: AxiosResponse): ICopyStakeReportResponse => data);
}

export async function getCopyStakeReportPdf(
  params: ICopyStakeReportPdfParams
): Promise<any> {
  return http.get(`${API_STATS()}/report/pdf`, {
    responseType: 'blob',
    params
  });
}

export const getCopyBetStatsStreamer = (
  params: ICopyStakeStatsStreamerParams
): Promise<ICopyStakeStatsStreamersResponse> => {
  return http
    .get(`${API_STATS()}/streamer`, {
      params,
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse<ICopyStakeStatsStreamersResponse>) => data);
};

export const getCopyBetStatsStreamerCsv = (
  params: DateRangeParams
): Promise<string> => {
  return http
    .get(`${API_STATS()}/streamer/csv`, {
      params
    })
    .then(({ data }: AxiosResponse<string>) => data);
};

export const getStreamingScheduleList = (
  params: IStreamingScheduleListParams
): Promise<IStreamingScheduleListResponse> =>
  http
    .get(API_SCHEDULE(), {
      params,
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse<IStreamingScheduleListResponse>) => data);

export const createScheduleStream = (
  payload: FormData
): AxiosPromise<TScheduleStreamResponse> =>
  http.post(API_SCHEDULE(), payload, {
    headers: {
      'Content-Type': undefined
    }
  });

export const updateScheduleStream = (
  scheduleId: number | string,
  payload: FormData
): Promise<TScheduleStreamResponse> =>
  http.put(`${API_SCHEDULE()}/${scheduleId}`, payload, {
    headers: {
      'Content-Type': undefined
    }
  });

export const deleteScheduleStream = (scheduleId: string): Promise<void> =>
  http.delete(buildUrl(`${API_SCHEDULE()}/${scheduleId}`));

export async function getCopyStakeGames(
  params: ICopyStakeGamesParams
): Promise<ICopyStakeGamesResponse> {
  return http
    .get(API_GAME(), {
      params,
      paramsSerializer: {
        indexes: null
      },
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): ICopyStakeGamesResponse => data);
}

export async function updateCopyStakeGamePreview(
  payload: FormData,
  id: string
): Promise<ICopyStakeGameItemResponse> {
  return http
    .put(`${API_GAME()}/${id}/image`, payload)
    .then(({ data }: AxiosResponse): ICopyStakeGameItemResponse => data);
}

export async function setPermissionGame(
  enabled: boolean,
  data: number[]
): Promise<string> {
  return http
    .patch(API_GAME_PERMISSION(), data, {
      params: {
        enabled
      }
    })
    .then(({ data }: AxiosResponse): string => data);
}

export async function setPermissionGameDisabledAll(
  params: ICopyStakeGamesRemoveAllParams
): Promise<string> {
  return http
    .patch(`${API_GAME_PERMISSION}/disable-all`, null, {
      params,
      paramsSerializer: {
        indexes: null
      }
    })
    .then(({ data }: AxiosResponse): string => data);
}

export async function getCopyStakeUsersBlacklist(
  params: ICopyStakeUsersBlacklistParam
): Promise<ICopyStakeUsersBlacklistResponse> {
  return http
    .get(API_BLACKLIST(), {
      params,
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): ICopyStakeUsersBlacklistResponse => data);
}

export async function addCopyStakeUserBlacklist(
  data: string[]
): Promise<string[]> {
  return http
    .post(API_BLACKLIST(), data)
    .then(({ data }: AxiosResponse): string[] => data);
}

export async function removeCopyStakeUserBlacklist(
  data: string[]
): Promise<string[]> {
  return http
    .delete(API_BLACKLIST(), { data })
    .then(({ data }: AxiosResponse): string[] => data);
}

export async function removeAllCopyStakeUserBlacklist(): Promise<string[]> {
  return http
    .delete(`${API_BLACKLIST()}/users`)
    .then(({ data }: AxiosResponse): string[] => data);
}

export const getCopystakeIntegration = (): Promise<TCopystakeIntegration> => {
  return http
    .get(API_INTEGRATION())
    .then(({ data }: AxiosResponse<TCopystakeIntegration>) => data);
};

export const updateCopystakeIntegration = (
  params: TCopystakeIntegration
): Promise<void> => {
  return http.put(API_INTEGRATION(), null, { params });
};
